import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { fallbackRenderNotFound } from '#screens/errors/fallbackRenderNotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { page, Titled } from '#util/title';

/**
 * {@link relaysRootRoute} contains all of the routing config for the /relays path
 */
export const relaysRootRoute = (
  <Route
    element={
      <Titled title={() => page('Relays')}>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Titled>
    }
  >
    <Route
      index={true}
      lazy={() => import('#route-bundles/authenticated').then(({ RelaysPage }) => ({ Component: RelaysPage }))}
    />
    <Route path="add">
      <Route
        index={true}
        lazy={() => import('#route-bundles/authenticated').then(({ AddRelayPage }) => ({ Component: AddRelayPage }))}
      />
      {['general', 'advanced'].map((path) => (
        // TODO: [2024-10-01] [@jasikpark] remove this fallback after webclients redirect
        <Route key={path} path={path} element={<Navigate to={`..?tab=${path}`} replace={true} />} />
      ))}
    </Route>
    <Route
      path=":hostId"
      element={
        <ErrorBoundary fallbackRender={fallbackRenderNotFound}>
          <Outlet />
        </ErrorBoundary>
      }
    >
      <Route
        index={true}
        lazy={() =>
          import('#route-bundles/authenticated').then(({ RelayDetailPage }) => ({
            element: (
              <Titled title={() => page('Relay')}>
                <RelayDetailPage />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path="enroll"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EnrollPage }) => ({
            element: (
              <Titled title={() => page('Enroll relay')}>
                <EnrollPage />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path="unblock"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EnrollPage }) => ({
            element: (
              <Titled title={() => page('Unblock relay')}>
                <EnrollPage verb="unblock" />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path="debug"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ DebugPage }) => ({
            element: <DebugPage />,
          }))
        }
      />
      <Route path="edit">
        <Route
          index={true}
          lazy={() =>
            import('#route-bundles/authenticated').then(({ EditRelayPage }) => ({
              Component: EditRelayPage,
            }))
          }
        />
        {['general', 'advanced'].map((path) => (
          // TODO: [2024-10-01] [@jasikpark] remove this fallback after webclients redirect
          <Route key={path} path={path} element={<Navigate to={`..?tab=${path}`} replace={true} />} />
        ))}
      </Route>
    </Route>
  </Route>
);
