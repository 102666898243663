import { lazy, Suspense } from 'react';

/**
 * Devtools, but not in production
 */
export function Devtools() {
  return (
    <Suspense>
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
    </Suspense>
  );
}

const ReactQueryDevtools =
  process.env.NODE_ENV === 'production' || import.meta.env.STORYBOOK ?
    () => null // Render nothing in production
  : lazy(() =>
      // Lazy load in development
      import('@tanstack/react-query-devtools').then((res) => ({
        default: res.ReactQueryDevtools,
      }))
    );
