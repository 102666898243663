import cn from 'classnames';
import styles from './Spindown.module.css';

type Props = {
  /** Arrow points down when expanded */
  isExpanded: boolean;
  isMobile?: boolean;
  size?: number;
  className?: string;
} & React.HTMLAttributes<SVGElement>;

/**
 * Used to indicate something is expandable/collapsable, such as a disclosure or accordion.
 */
export function Spindown({ isExpanded, isMobile, className, size = 10, ...props }: Props) {
  return (
    <svg
      className={cn(styles.Spindown, className, {
        [styles.Spindown___isExpanded]: isExpanded,
        [styles.Spindown___isMobile]: isMobile,
      })}
      viewBox="0 0 16 16"
      height={size}
      width={size}
      fill="currentColor"
      {...props}
    >
      <path d="M13 8a1.534 1.534 0 01-.52 1.153l-7.435 6.54a1.231 1.231 0 01-1.626-1.848l6.502-5.72a.167.167 0 000-.25l-6.502-5.72A1.231 1.231 0 015.045.307l7.432 6.538A1.54 1.54 0 0113 8z" />
    </svg>
  );
}
