import cn from 'classnames';
import { useMatch } from 'react-router-dom';
import { Logo } from '#components/atoms/logo/Logo';
import styles from './AuthLayout.module.css';

type Props = {
  children: React.ReactNode;
  /** This will be shown in the top-right of the page */
  headerText?: React.ReactNode;
  /** Should children be centered vertically? */
  centerVertically?: boolean;
};

export function AuthLayout({ children, headerText, centerVertically }: Props) {
  const signupMatch = useMatch('/signup/*');
  const loginMatch = useMatch('/auth/login');

  return (
    <>
      <header className={styles.AuthLayout_header}>
        <div className={styles.AuthLayout_headerContainer}>
          {(signupMatch ?? loginMatch) ?
            <a href="https://www.defined.net/">
              <span className="visually-hidden">Defined Networking Home</span>
              <Logo height={32} />
            </a>
          : <a href="/">
              <span className="visually-hidden">Home</span>
              <Logo height={32} />
            </a>
          }

          {!!headerText && <span className={styles.AuthLayout_headerText}>{headerText}</span>}
        </div>
      </header>
      <main className={cn(styles.AuthLayout_main, { [styles.AuthLayout_main___centered]: centerVertically })}>
        {children}
      </main>
    </>
  );
}
