import { useEffect, useState } from 'react';
import { trackError } from '#api/errors';
import { useTimeout } from '#hooks/useTimeout';
import { copyToClipboard } from '#util/copy-to-clipboard';
import { Tooltip, type Placement } from '../../tooltip/Tooltip';
import { CopyIconButton } from '../icon';
import type { IconButtonProps } from '../icon/types';

type Props = {
  value: string;
  placement?: Placement;
  initialTooltip?: string;
  successTooltip?: string;
} & IconButtonProps;

export function CopyButton({
  value,
  placement = 'right',
  initialTooltip = 'Copy',
  successTooltip = 'Copied!',
  ...buttonProps
}: Props) {
  const [tooltip, setTooltip] = useState(initialTooltip);
  const { start: resetTooltipAfterDelay, clear: clearTimeout } = useTimeout(() => {
    setTooltip(initialTooltip);
  }, 2000);

  // Reset the tooltip after 2 seconds
  useEffect(() => {
    if (tooltip === successTooltip) {
      resetTooltipAfterDelay();
    }
  }, [resetTooltipAfterDelay, successTooltip, tooltip]);

  return (
    <Tooltip
      content={tooltip}
      placement={placement}
      onHidden={() => {
        setTooltip(initialTooltip);
        clearTimeout();
      }}
      render={
        <CopyIconButton
          {...buttonProps}
          onClick={() => {
            copyToClipboard(value)
              .then(() => {
                return setTooltip(successTooltip);
              })
              .catch((err: unknown) => {
                console.error('Could not copy');
                setTooltip('Unable to copy');
                trackError(err);
              });
          }}
        />
      }
    />
  );
}
