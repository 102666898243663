import * as Sentry from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/fp/isEqual';
import { useState } from 'react';
import { Auth, TOTP, User, userQueryKeys } from '#api';
import { AuthenticationError, trackError } from '#api/errors';
import type { Organization } from '#dn-types/org';
import { useAuthTokens } from '#hooks/useAuthTokens';
import { useBrowserStorage } from '#hooks/useBrowserStorage';

const USER_TOKEN_EXPIRE_DAYS = 365 * 10; // 10 years, rely on redis
const AUTH_TOKEN_EXPIRE_DAYS = 0.67; // ~16 hours
const OIDC_TOKEN_EXPIRE_DAYS = 0.67; // ~16 hours

export type AuthUser =
  | {
      type: 'user';
      id: string;
      organizationID: string;
      email: string;
      hasTOTPAuthenticator: boolean;
      organization: Organization;
    }
  | {
      type: 'oidcUser';
      id: string;
      organizationID: string;
      email: string;
      organization: Organization;
    };

/**
 * {@link useAuthProvider} hook creates auth object and handles state.
 * Any authentication APIs that change auth state should be called through
 * these methods, so that we can correctly track auth state and redirect as needed.
 *
 * @param initialState is used in testing only.
 */
export function useAuthProvider(initialState?: {
  user?: AuthUser;
  // `useAuthProvider` will not re-query the user without some form of token, important to pass for stories with a changing user.
  userToken?: string;
  authToken?: string;
  oidcToken?: string;
}) {
  const [user, setUser] = useState<AuthUser | undefined>(initialState?.user);
  const [oidcStateCode, setOIDCStateCode, clearOIDCStateCode] = useBrowserStorage<string>('oidc-state');
  // Once a user visits the oidc login url, we save it in localStorage to allow automatic redirects when tokens
  // expire and a button on the login page for SSO.  It needs to be cleared if the server returns a 404 when starting oidc,
  // which means the identity provider was deleted.
  const [oidcLoginURL, setOIDCLoginURL, clearOIDCLoginURL] = useBrowserStorage<string>('loginURL', 'local');
  // Should we automatically redirect to the oidc login url?
  // We track this separately from the login url itself so that we can allow logout without clearing the login url.
  const [redirectToOIDC, setRedirectToOIDC, clearRedirectToOIDC] = useBrowserStorage<string>('redirectToOIDC', 'local');
  // When performing OIDC re-auth, we want to return the user to the page they were on in our app
  const [oidcFrom, setOIDCFrom, clearOIDCFrom] = useBrowserStorage<string>('oidc-from');
  const [failedFetchingUser, setFailedFetchingUser] = useState<boolean>(false);
  const {
    userToken,
    setUserToken,
    deleteUserToken,
    authToken,
    setAuthToken,
    deleteAuthToken,
    oidcToken,
    setOIDCToken,
    deleteOIDCToken,
  } = useAuthTokens(initialState?.userToken, initialState?.authToken, initialState?.oidcToken);
  const queryClient = useQueryClient();

  // If we have a userToken or oidcToken, get user information
  const { data: fetchedUser, refetch: refetchUser } = useQuery({
    queryKey: userQueryKeys.current(),
    queryFn: ({ signal }) => User.whoami({ signal }),
    enabled: Boolean(userToken || oidcToken),
    retry: (failureCount, err) => {
      // If this was an auth error, check if we need to clear tokens
      if (err instanceof AuthenticationError) {
        // We may have already cleared the token by the time the 401 happens, only sign out if needed
        if (userToken || oidcToken) {
          // Clearing auth does not reset the redirectToOIDC state nor the user token,
          // which lets us show the correct login page
          if (oidcToken || err.code === 'ERR_2FA_REQUIRED') {
            clearAuth();
            // Cancel any queries that are still in flight, we don't want to trigger multiple calls to clearAuth.
            // The fetches will still happen unless they consume the abort signal from react-query, but they won't be handled by react-query.
            void queryClient.cancelQueries();
          } else if (err.code === 'ERR_UNAUTHORIZED') {
            void signout();
          }
        }
      }
      // Otherwise, it was something unexpected, so log it
      else {
        setFailedFetchingUser(true);
        trackError(err);
      }

      // Don't retry auth error, otherwise only retry once, so we can show an error screen quickly if needed.
      return !(err instanceof AuthenticationError) && failureCount < 1;
    },
    // Don't need to automatically refetch user info after we've gotten it. It won't change on its own
    staleTime: Number.POSITIVE_INFINITY,
  });

  // Compare a fetched user against the user in state, to see if it has changed
  if (fetchedUser) {
    if (fetchedUser.actorType === 'apiKey') {
      throw Error("api key auth shouldn't be seen in webclient");
    }
    const newUser: AuthUser =
      fetchedUser.actorType === 'user' ?
        { ...fetchedUser.actor, type: 'user', organization: fetchedUser.organization }
      : { ...fetchedUser.actor, type: 'oidcUser', organization: fetchedUser.organization };

    if (!isEqual(newUser, user)) {
      setUser(newUser);
      // eslint-disable-next-line import/namespace
      Sentry.setContext('user', newUser);
    }
  }

  function sendMagicLink(email: string) {
    return Auth.sendMagicLink({ email });
  }

  function verifyMagicLink(magicLinkToken: string) {
    return Auth.verifyMagicLink({ magicLinkToken }).then((response) => {
      return setUserToken(response.sessionToken, USER_TOKEN_EXPIRE_DAYS);
    });
  }

  function getAuthState() {
    if (!userToken && !oidcToken) return 'unauthenticated';
    if (userToken && !authToken) return 'mfaRequired';
    return 'authenticated';
  }

  // We can use this function to determine whether to show the mfa login or mfa registration page
  function hasUserRegisteredMFA() {
    if (failedFetchingUser) return 'error';
    if (!user) return 'unknown';
    if (user.type === 'oidcUser') return 'n/a';
    if (user.hasTOTPAuthenticator) return 'yes';
    return 'no';
  }

  function registerMFA(formData: Parameters<typeof TOTP.registerMFA>[0]) {
    if (!userToken) {
      // This should not happen, because this method is called from a protected route
      // which will redirect to the login screen if no user token is set.  But if it
      // does happen, we'll want to know about it.
      trackError(new Error('No user is set when calling registerMFA'));
    }
    return TOTP.registerMFA(formData).then((response) => {
      void refetchUser();
      return setAuthToken(response.authToken, AUTH_TOKEN_EXPIRE_DAYS);
    });
  }

  // Authenticate the user using a code from their 2FA app.
  // By the time this is used, the user should already have a valid user token.
  function loginWithMFA(code: string) {
    if (!userToken) {
      // This should not happen, because this method is called from a protected route
      // which will redirect to the login screen if no user token is set.  But if it
      // does happen, we'll want to know about it.
      trackError(new Error('No user is set when calling loginWithMFA'));
    }
    return Auth.loginMFA({ code }).then((response) => {
      // Do not automatically redirect to OIDC login next time
      clearRedirectToOIDC();
      // Clear out oidc state, to prevent conflicts
      deleteOIDCToken();
      clearOIDCStateCode();
      return setAuthToken(response.authToken, AUTH_TOKEN_EXPIRE_DAYS);
    });
  }

  /**
   * This kicks off the OIDC flow, requesting some state and a redirect url from our server,
   * then saving off that state into browser storage and redirecting to the identity provider's login.
   */
  function startOIDC(orgId: string) {
    // Remove any pre-existing auth state in the browser, to avoid conflicts between TOTP and OIDC auth
    _clearBrowserAuthState();

    return Auth.startOIDCAuth(orgId).then((response) => {
      setOIDCStateCode(response.oidcRequestToken);
      // This is used in LoginPage to redirect the user to the login url if their auth has expired.
      setOIDCLoginURL(window.location.pathname);
      // Technically the truthy check is unneeded, except in storybook where we do not want to actually redirect.
      if (response.redirectURL) {
        // Don't allow returning to our login page URL with the back button, would just cause another redirect.
        window.location.replace(response.redirectURL);
      }

      return;
    });
  }

  /**
   * Once the user hits our OIDC callback, we need to send the state code from the browser's storage, along with
   * the current url (including params that the identity provider added) to the server, in exchange for an
   * OIDC authentication+session token.
   */
  function verifyOIDC() {
    if (typeof oidcStateCode !== 'string') {
      return Promise.reject(new Error('Could not find oidc state code in storage.'));
    }
    const location = window.location.href;
    return Auth.verifyOIDCAuth({ oidcRequestToken: oidcStateCode, location }).then((response) => {
      setOIDCToken(response.authToken, OIDC_TOKEN_EXPIRE_DAYS);
      setRedirectToOIDC('1');

      // eslint-disable-next-line promise/no-nesting
      return refetchUser().then(() => {
        // Provide the oidcFrom so the caller can redirect
        return oidcFrom;
      });
    });
  }

  function signout() {
    return Auth.logout()
      .catch((err: unknown) => {
        if (err instanceof AuthenticationError) {
          // no-op, mission accomplished.
        } else {
          trackError(err);
        }
      })
      .finally(_clearBrowserAuthState);
  }

  /**
   * Clear out (a presumably expired) authentication token, so that the 2fa screen will be shown
   */
  function clearAuth() {
    if (oidcToken) {
      // Remove origin, because we're going to use this to client route
      const currentOidcFrom = location.href.replace(location.origin, '');
      // Prevent OIDC login loop
      if (!currentOidcFrom.startsWith('/auth/login/org-')) {
        setOIDCFrom(currentOidcFrom);
      }
    }
    // Cancel out any in-flight queries and remove all queries from the cache, except for whoami
    // Note: don't use queryClient.clear() here, because it can throw a CancelledError
    void queryClient.cancelQueries({ predicate: (query) => query.queryKey !== userQueryKeys.current() });
    queryClient.removeQueries({ predicate: (query) => query.queryKey !== userQueryKeys.current() });
    // Remove the auth token, which will cause a redirect to an mfa page (login or registration) or oidc login
    deleteAuthToken();
    deleteOIDCToken();
  }

  /**
   * This private function clears out all existing authentication cookies, user information, and query cache.
   *
   * It does not clear the login url from localStorage, so we can show that option on the login page
   */
  function _clearBrowserAuthState() {
    setUser(undefined);
    queryClient.clear();
    clearRedirectToOIDC();
    clearOIDCStateCode();
    deleteUserToken();
    deleteAuthToken();
    deleteOIDCToken();
  }

  // Return the user object and auth methods
  return {
    user,
    userToken,
    authToken,
    oidcToken,
    oidcLoginURL,
    redirectToOIDC: Boolean(redirectToOIDC),
    sendMagicLink,
    verifyMagicLink,
    signout,
    registerMFA,
    loginWithMFA,
    getAuthState,
    clearOIDCFrom,
    startOIDC,
    verifyOIDC,
    clearOIDCLoginURL,
    hasUserRegisteredMFA,
    clearAuth,
  };
}
