import { useStoreState } from '@ariakit/react';
import { Disclosure, DisclosureContent, useDisclosureStore } from '@ariakit/react/disclosure';
import cn from 'classnames';
import { Children, isValidElement, useEffect, useMemo, useState } from 'react';
import { Spindown } from '#components/atoms/spindown/Spindown';
import { useAppLocation } from '#hooks/useAppLocation';
import styles from './NavGroup.module.css';

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (shouldOpen: boolean) => void;
  isActive: boolean;
  setActive: (active: boolean) => void;
  children: React.ReactNode;
  className?: string;
};

export function NavGroup({ children, className, isOpen, setOpen, isActive, setActive, title }: Props) {
  const { pathname } = useAppLocation();

  const disclosure = useDisclosureStore({
    animated: true,
    open: isOpen,
    setOpen: handleOpenChange,
  });
  const animating = useStoreState(disclosure, 'animating');
  function handleOpenChange(open: boolean) {
    // While animating, "open" is still in the old state, so we have to wait
    if (!animating && open !== isOpen) {
      setOpen(open);
    }
  }

  // Check the list of "to" props of sub-nav items, so we can be sure to treat this as active when one of those are visited
  const hasMatchingChildren = useMemo(() => {
    const targets = Children.toArray(children).map(
      (child) => (isValidElement<{ to: string }>(child) && child.props.to) || ''
    );

    return targets.some((target) => pathname.startsWith(target));
  }, [children, pathname]);

  useEffect(() => {
    if (hasMatchingChildren) {
      // Show as active when the user is on a sub-nav item's page
      setActive(true);
    } else {
      setActive(false);
    }
  }, [hasMatchingChildren, setActive]);

  const [previousHasMatchingChildren, setPreviousHasMatchingChildren] = useState<boolean | null>(null);
  useEffect(() => {
    if (hasMatchingChildren !== previousHasMatchingChildren) {
      setPreviousHasMatchingChildren(hasMatchingChildren);

      // Default to open when the user is on a sub-nav item's page (but the user can still close it)
      if (!previousHasMatchingChildren && hasMatchingChildren) {
        setOpen(true);
      } else if (previousHasMatchingChildren && !hasMatchingChildren) {
        // If we were just active, close the NavGroup
        setOpen(false);
      }
    }
  }, [hasMatchingChildren, previousHasMatchingChildren, setOpen]);

  const classes = cn(styles.NavGroup, className, { [styles.NavGroup___active]: isActive });

  const subItems = Children.map(children, (child) => {
    if (!isValidElement<{ to: string }>(child)) {
      return child;
    }

    return <li key={child.props.to}>{child}</li>;
  });

  if (!subItems || subItems.length === 0) {
    throw new Error('Must provide one or more children to NavGroup');
  }

  if (subItems.length > 6) {
    throw new Error('Must update NavGroup styles to support more than 6 items');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuItemsLengthClass = (styles as any)[`NavGroup_menu___items${subItems.length}`] as string;

  return (
    <>
      <Disclosure store={disclosure} className={classes}>
        {title}
        <Spindown className={styles.NavGroup_arrow} isExpanded={isOpen} isMobile={true} />
      </Disclosure>

      <DisclosureContent store={disclosure} className={cn(styles.NavGroup_menu, menuItemsLengthClass)}>
        <ul className={styles.NavGroup_subitems}>{subItems}</ul>
      </DisclosureContent>
    </>
  );
}
