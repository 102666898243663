import type { Organization } from '#dn-types/org';
import type { Actor } from '#dn-types/user';
import { get } from './fetch';

// GET /v2/whoami
export type WhoAmIResult = Actor & {
  organization: Organization;
};

export async function whoami(init?: RequestInit) {
  return get<WhoAmIResult>('/v2/whoami', init).then((res) => res.data);
}
