import { rewordValidationMessages } from '#api/errors';
import type { FeatureFlags } from '#dn-types/org';
import { del, get } from './fetch';

// GET /feature-flags
export type FeatureFlagsResult = Partial<FeatureFlags>; // Partial, because we need to handle cases where the api does not yet support the flag
export async function getFeatureFlags(init?: RequestInit) {
  return get<FeatureFlagsResult>('/v1/feature-flags', init).then((res) => res.data);
}

type DeleteOrgResult = Record<string, never>;
export function deleteOrg(orgId: string) {
  return del<DeleteOrgResult>(`/v1/organizations/${orgId}`).catch((err: unknown) => {
    rewordValidationMessages(err, (details) => {
      if (details.path === undefined && details.code === 'ERR_BILLING_INVALID_STATE') {
        return 'Please downgrade to the Free plan before deleting your account.';
      }
    });
  });
}
