import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route } from 'react-router-dom';
import { NotFound } from '#screens/404/NotFound';
import { fallbackRenderNotFound } from '#screens/errors/fallbackRenderNotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { IsMFAUser } from '#screens/settings/IsMFAUser.jsx';
import { fromArray, page, Titled } from '#util/title';
import { billingRootRoute } from './billing/BillingRoot.jsx';
import { networksRootRoute } from './networks/NetworksRoot.jsx';

/**
 * {@link settingsRootRoute} contains all of the routing config for the /settings path
 */
export const settingsRootRoute = (
  <Route
    element={
      <Titled title={() => page('Settings')}>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Titled>
    }
  >
    <Route
      path="user"
      lazy={() =>
        import('#route-bundles/authenticated').then(({ UserPage }) => ({
          element: (
            <IsMFAUser>
              <UserPage />
              <Outlet />
            </IsMFAUser>
          ),
        }))
      }
    >
      <Route
        path="totps/add"
        lazy={() => import('#route-bundles/authenticated').then(({ AddTOTPModal }) => ({ Component: AddTOTPModal }))}
      />
      <Route
        path="totps/:totpId/edit"
        lazy={() => import('#route-bundles/authenticated').then(({ EditTOTPModal }) => ({ Component: EditTOTPModal }))}
      />
      <Route
        path="totps/:totpId/delete"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ DeleteTOTPModal }) => ({
            Component: DeleteTOTPModal,
          }))
        }
      />
    </Route>
    <Route path="billing">{billingRootRoute}</Route>
    <Route path="networks">{networksRootRoute}</Route>
    <Route path="api-keys">
      <Route
        path="add"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ AddAPIKeyPage }) => ({
            element: (
              <Titled title={(title) => fromArray(['Add API key', title])}>
                <AddAPIKeyPage />
              </Titled>
            ),
          }))
        }
      />
      <Route
        index={true}
        lazy={() =>
          import('#route-bundles/authenticated').then(({ APIKeysPage }) => ({
            element: (
              <Titled title={(title) => fromArray(['API keys', title])}>
                <APIKeysPage />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path=":apiKeyID"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ APIKeyDetailPage }) => ({
            element: (
              <Titled title={(title) => fromArray(['Edit API Key', title])}>
                <ErrorBoundary fallbackRender={fallbackRenderNotFound}>
                  <APIKeyDetailPage />
                </ErrorBoundary>
              </Titled>
            ),
          }))
        }
      />
    </Route>
    <Route path="sso">
      <Route
        path="edit"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EditIdentityProviderPage }) => ({
            element: (
              <IsMFAUser>
                <Titled title={(title) => fromArray(['Edit SSO', title])}>
                  <EditIdentityProviderPage />
                </Titled>
              </IsMFAUser>
            ),
          }))
        }
      />
      <Route
        index={true}
        lazy={() =>
          import('#route-bundles/authenticated').then(({ IdentityProviderPage }) => ({
            element: (
              <Titled title={(title) => fromArray(['SSO', title])}>
                <IdentityProviderPage />
              </Titled>
            ),
          }))
        }
      />
    </Route>
    <Route index={true} path="*" element={<NotFound />} />
  </Route>
);
