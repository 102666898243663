import cn from 'classnames';
import { Children } from 'react';
import { CopyButton } from '#components/atoms/buttons/copy-button/CopyButton';
import { CodeBlock } from '#components/atoms/code/CodeBlock';
import styles from './Snippet.module.css';

// Horizontal scroll breaks Snippets, don't allow it
type Props = Omit<React.ComponentPropsWithoutRef<typeof CodeBlock>, 'allowScroll'> & {
  children: string | string[];
  className?: string;
  disabled?: boolean;
  secondaryButton?: React.ReactElement;
};

export function Snippet({ children, disabled, className, secondaryButton, ...codeProps }: Props) {
  const code = Children.map(children, (child) => {
    if (child === '\n') return null;

    return (
      <code>
        {child}
        {'\n'}
      </code>
    );
  });
  return (
    <CodeBlock
      className={cn(styles.Snippet, className, {
        [styles.Snippet___isDisabled]: disabled,
      })}
      {...codeProps}
    >
      <div className="mw-0">{code}</div>
      <div className={cn(styles.Snippet_buttons)}>
        {secondaryButton}
        <CopyButton disabled={disabled} value={Array.isArray(children) ? children.join('\n') : children} />
      </div>
    </CodeBlock>
  );
}
