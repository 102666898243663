import { useState } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import type { SetFieldType, SetOptional } from 'type-fest';
import { trackError, ValidationError } from '#api/errors';
import { Button } from '#components/atoms/buttons/Button';
import { Spindown } from '#components/atoms/spindown/Spindown';
import { Snippet } from '#components/patterns/code/snippet/Snippet';
import { CenteredLayout } from '#components/patterns/layouts/centered/CenteredLayout';
import styles from './Oops.module.css';

type Props = {
  resetButtonText?: string;
  className?: string;
  showErrorDetails: boolean;
} & SetOptional<SetFieldType<FallbackProps, 'error', unknown>, 'resetErrorBoundary'>;

export function Oops({ className, resetButtonText = 'Refresh', showErrorDetails, error, resetErrorBoundary }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  if (error) {
    trackError(error);
  }

  return (
    <CenteredLayout className={className}>
      <div role="alert">
        <h2 className="h3 mb-8">Oops, something went wrong</h2>
        <p className="p mb-24">
          The Defined Networking team has been notified. You can try refreshing or contacting us via{' '}
          <a className="a" href="mailto:support@defined.net?subject=Report%20a%20Bug">
            support@defined.net
          </a>
          .
        </p>
        <Button
          className="mb-24"
          variant="secondary"
          onClick={() => {
            resetErrorBoundary ? resetErrorBoundary() : window.location.reload();
          }}
        >
          {resetButtonText}
        </Button>
        {showErrorDetails && error ?
          <details
            onToggle={(event) => {
              const detailsElement = event.target as HTMLDetailsElement;
              setIsOpen(detailsElement.open);
            }}
          >
            <summary className={styles.Oops_summary}>
              <Spindown className="mr-8" isExpanded={isOpen} aria-hidden="true" />
              Error details:
            </summary>
            <Snippet>
              {error instanceof ValidationError ?
                // Just pull out the parts that are interesting, not the path and 'reworded'
                JSON.stringify(
                  error.errors.map((err) => ({ message: err.message, code: err.code })),
                  null,
                  2
                )
              : error instanceof Error ?
                error.message
              : JSON.stringify(error)}
            </Snippet>
          </details>
        : null}
      </div>
    </CenteredLayout>
  );
}
