import { useQuery } from '@tanstack/react-query';
import { usePrevious } from '#hooks/usePrevious';

const REFETCH_INTERVAL_MS = 10 * 60 * 1000; // Ten minute interval

export function useAppUpdateAvailable(initialVersion?: string, { enabled } = { enabled: true }) {
  const version = useQuery({
    queryKey: ['app', 'version', location.origin],
    queryFn: async ({ signal }) => {
      const response = await fetch(`${location.origin}/v`, { credentials: 'omit', signal });

      if (response.ok) {
        return await response.text();
      }

      return null;
    },
    refetchInterval: REFETCH_INTERVAL_MS,
    staleTime: REFETCH_INTERVAL_MS / 2,
    enabled,
    // This is passed in Storybook tests currently.
    // Should be placeholder data so that Storybook tests pass w/o waiting 10m.
    placeholderData: initialVersion,
  }).data;

  const previousVersion = usePrevious(version);

  const updateAvailable = previousVersion !== undefined && version !== previousVersion;

  return updateAvailable;
}
