import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './Button.module.css';

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  /** The type of button this should be.  Note, the icon variant is different from an icon button, and is similar to a secondary button with different padding and color */
  variant?: 'primary' | 'secondary' | 'danger' | 'link' | 'linkPrimary' | 'icon';
  /** Size controls the padding, height, and font-size */
  size?: 'xsmall' | 'small' | 'medium';
  /** Optional classes to apply to the button */
  className?: string;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ variant = 'primary', children, size = 'medium', className, ...htmlProps }, ref) => {
    const classes = cn(
      'btn',
      styles.Button,
      styles[`Button___${variant}` as const],
      styles[`Button___${size}` as const],
      className
    );

    return (
      <button className={classes} type="button" ref={ref} {...htmlProps}>
        {children}
      </button>
    );
  }
);
Button.displayName = 'Button';
