import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './Spinner.module.css';

const LARGE_SIZE = 48;

type Props = {
  size?: number;
  className?: string;
  state?: 'spinning' | 'success';
} & React.SVGAttributes<SVGElement>;

/**
 * Shows an animated spinner, to indicate loading or processing.  Can transition
 * to a "success" state, including a checkmark.  Sizes 48px and larger keep a ring
 * around the checkmark.
 */
export function Spinner({ className, size = 32, state = 'spinning', ...props }: Props) {
  const spinnyRef = useRef<SVGCircleElement>(null);
  const [stopSpin, setStopSpin] = useState(state !== 'spinning');
  // We change some styles when the total size of the spinner is large
  const isLarge = size >= LARGE_SIZE;

  // Wait until an animation iteration has finished to stop the spinning
  useEffect(() => {
    function handleIteration() {
      if (state !== 'spinning' && !stopSpin) {
        setStopSpin(true);
      }
    }
    const currentRef = spinnyRef.current;
    currentRef?.addEventListener('animationiteration', handleIteration);
    return () => {
      currentRef?.removeEventListener('animationiteration', handleIteration);
    };
  }, [spinnyRef, state, stopSpin]);

  // Not generally used, but allow return to spinning (helpful for storybooks)
  useEffect(() => {
    if (state === 'spinning' && stopSpin) {
      setStopSpin(false);
    }
  }, [state, stopSpin]);

  // Tweak the checkmark a bit when shown inside a circle
  const checkmarkD = isLarge ? 'm9.6 14.5 2 2 1 1 6-6' : 'm6 16 4 4 1 1 11-11';

  return (
    <svg
      className={cn(styles.Spinner, className, {
        [styles.Spinner___large]: isLarge,
        [styles.Spinner___success]: state === 'success',
      })}
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <title>{state === 'success' ? 'A checkmark' : 'A loading spinner'}</title>
      <circle className={styles.Spinner_ring} cx="14" cy="14" r="12" />
      <circle
        ref={spinnyRef}
        className={cn(styles.Spinner_spinnybit, { [styles.Spinner_spinnybit___isStopped]: stopSpin })}
        cx="14"
        cy="14"
        r="12"
        strokeDasharray="75"
      />
      <path className={styles.Spinner_checkmark} d={checkmarkD} />
    </svg>
  );
}
