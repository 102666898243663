import type { FallbackProps } from 'react-error-boundary';
import { MissingResourceError } from '#api/errors';
import { NotFound } from '#screens/404/NotFound';

export function makeFallbackRenderNotFound(fallback = <NotFound />) {
  return function fallbackRender({ error }: FallbackProps) {
    if (error instanceof MissingResourceError) {
      return fallback;
    }
    throw error;
  };
}

export const fallbackRenderNotFound = makeFallbackRenderNotFound();
