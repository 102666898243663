import { useCallback, useState } from 'react';

export type InitialState = Notification[];

type NotificationType = 'alert' | 'info';

type Notification = {
  id: string;
  type: NotificationType;
  children: React.ReactNode;
};

/**
 * {@link useNotificationsProvider} hook creates and manages global banner state.
 */
export function useNotificationsProvider(initialState: InitialState = []) {
  const initialNotifications = initialState;
  const [notifications, setNotifications] = useState(initialNotifications);

  const removeNotification = useCallback(
    (id: string) => {
      const filteredNotifications = notifications.filter((n) => n.id !== id);
      setNotifications(filteredNotifications);
    },
    [notifications]
  );

  const add = useCallback(
    (n: Notification) => {
      setNotifications([...notifications, n]);
    },
    [notifications]
  );

  function addNotification(n: Notification) {
    add(n);
  }

  // Return the state and setters
  return {
    notifications,
    addNotification,
    removeNotification,
  };
}
