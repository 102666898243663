import { Navigate, Outlet, Route } from 'react-router-dom';
import { HasUser } from '#screens/auth/HasUser.jsx';
import { fromArray, Titled } from '#util/title';

/**
 * {@link authRootRoute} contains all of the routing config for the /auth path
 */
export const authRootRoute = (
  <Route
    element={
      <Titled title={(title) => fromArray(['Auth', title])}>
        <Outlet />
      </Titled>
    }
  >
    <Route
      path="login"
      lazy={() => import('#route-bundles/unauthenticated').then(({ LoginPage }) => ({ Component: LoginPage }))}
    />
    <Route
      path="login/:orgID"
      lazy={() => import('#route-bundles/unauthenticated').then(({ LoginOIDC }) => ({ Component: LoginOIDC }))}
    />
    <Route
      path="oidc-callback"
      lazy={() => import('#route-bundles/unauthenticated').then(({ CallbackOIDC }) => ({ Component: CallbackOIDC }))}
    />
    <Route
      path="magic-link"
      lazy={() => import('#route-bundles/unauthenticated').then(({ MagicLinkPage }) => ({ Component: MagicLinkPage }))}
    />
    <Route
      path="mfa"
      element={
        <HasUser>
          <Outlet />
        </HasUser>
      }
    >
      <Route
        path="register"
        lazy={() =>
          import('#route-bundles/unauthenticated').then(({ RegisterMFA }) => ({
            Component: RegisterMFA,
          }))
        }
      />
      <Route
        path="renew?"
        lazy={() =>
          import('#route-bundles/unauthenticated').then(({ LoginMFA }) => ({
            Component: LoginMFA,
          }))
        }
      />
    </Route>
    <Route path="*" element={<Navigate to="login" replace={true} />} />
  </Route>
);
