import { useContext } from 'react';
import { NotificationsContext } from './NotificationsContext';

/**
 * {@link useNotifications} can be used by components in our app to get and set global notifications.
 */
export function useNotifications() {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
}
