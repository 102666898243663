import { Spinner } from '#assets/Spinner';
import { CenteredLayout } from '#components/patterns/layouts/centered/CenteredLayout';

export function LoadingSpinner({ size = 48 }) {
  return (
    <CenteredLayout preventNotifications={true}>
      <Spinner size={size} />
    </CenteredLayout>
  );
}
