import { forwardRef } from 'react';
import { DeleteIcon } from '#assets/ReactSvg';
import { Tooltip } from '../../tooltip/Tooltip';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export const DeleteButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size, title = 'Delete', ...buttonProps }, ref) => {
    return (
      <Tooltip
        content={title}
        render={(htmlProps) => (
          <IconButton title={title} height={size} width={size} ref={ref} {...buttonProps} {...htmlProps}>
            <DeleteIcon />
          </IconButton>
        )}
      />
    );
  }
);
DeleteButton.displayName = 'DeleteButton';
