import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '#global/context/auth/AuthProvider';
import { GlobalStateProvider } from '#global/context/global-state/GlobalStateProvider';
import { NotificationsProvider } from '#global/context/notifications/NotificationsProvider';

type Props = {
  children: React.ReactNode;
  client: QueryClient;
};

/**
 * {@link ContextProviders} sets up all of our top-level react context providers that are needed
 * for the app.  By keeping them out of the App itself, we are more easily able to test
 * the App component with alternate context providers geared towards testing.
 */
export function ContextProviders({ children, client }: Props) {
  return (
    <QueryClientProvider client={client}>
      <GlobalStateProvider>
        <NotificationsProvider>
          <AuthProvider>{children}</AuthProvider>
        </NotificationsProvider>
      </GlobalStateProvider>
    </QueryClientProvider>
  );
}
