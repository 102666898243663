import { rewordValidationMessages, trackError } from './errors';
import { del, post } from './fetch';

// POST /signup
type PostUserVariables = {
  email: string;
  referer: string;
};
type SignupResult = never;
export function signup(formData: PostUserVariables) {
  return (
    post<SignupResult>('/v1/signup', formData, { headers: { Authorization: '' } })
      // Convert from server error codes to more friendly strings, if possible
      .catch((err: unknown) => {
        rewordValidationMessages(err, (details) => {
          if (details.path === undefined && details.code === 'ERR_TOO_MANY_REQUESTS') {
            return 'Too many requests, please try again later.';
          }
        });
      })
  );
}

// POST /auth/magic-link
type SendMagicLinkVariables = {
  email: string;
};
export type SendMagicLinkResult = Record<string, never>;
export function sendMagicLink(formData: SendMagicLinkVariables) {
  return post<SendMagicLinkResult>('/v1/auth/magic-link', formData, { headers: { Authorization: '' } }).catch(
    (err: unknown) => {
      rewordValidationMessages(err, (details) => {
        if (details.path === undefined && details.code === 'ERR_INVALID_EMAIL') {
          return 'That doesn’t look like a valid email format.';
        }
      });
    }
  );
}

// POST /auth/verify-magic-link
type VerifyMagicLinkVariables = {
  magicLinkToken: string;
};
export type VerifyMagicLinkResult = {
  sessionToken: string;
};
export function verifyMagicLink(formData: VerifyMagicLinkVariables) {
  return post<VerifyMagicLinkResult>('/v1/auth/verify-magic-link', formData, { headers: { Authorization: '' } }).catch(
    (err: unknown) => {
      rewordValidationMessages(err, (details) => {
        if (
          // Expired magic link.
          (details.path === 'magicLinkToken' && details.code === 'ERR_INVALID_MAGIC_LINK_TOKEN') ||
          // Invalid magic link, ex: it wasn't provided.
          (details.path === 'magicLinkToken' && details.code === 'ERR_INVALID_VALUE') ||
          (details.path === undefined && details.code === 'ERR_INVALID_VALUE')
        ) {
          // Note: this is handled by a redirect, the user shouldn't see this message
          return 'Magic link was invalid';
        }
      });
    }
  );
}

// DELETE /auth
type LogoutResult = Record<string, never>;
export function logout() {
  return del<LogoutResult>('/v1/auth');
}

// POST /auth/totp
type LoginMFAVariables = {
  code: string;
};
export type LoginMFAResult = {
  authToken: string;
};
export function loginMFA(formData: LoginMFAVariables) {
  return post<LoginMFAResult>('/v1/auth/totp', formData).catch((err: unknown) => {
    rewordValidationMessages(err, (details) => {
      if (details.path === 'code' && details.code === 'ERR_INVALID_TOTP_CODE') {
        return 'That code didn‘t work, please try again.';
      }
      if (details.path === undefined && details.code === 'ERR_2FA_ENROLLMENT_REQUIRED') {
        return 'TOTP (re)enrollment is required.';
      }
    });
  });
}

// POST /auth/oidc/start/:orgID
export type StartOIDCAuthResult = {
  redirectURL: string;
  oidcRequestToken: string;
};
export function startOIDCAuth(orgID: string) {
  return post<StartOIDCAuthResult>(`/v1/auth/oidc/start/${orgID}`).catch((err: unknown) => {
    rewordValidationMessages(err, (details) => {
      if (details.path === undefined && details.code === 'ERR_OIDC_NOT_CONFIGURED') {
        return 'Single Sign-On is not configured.';
      }
    });
  });
}

// POST /auth/oidc/verify
type VerifyOIDCAuthVariables = {
  oidcRequestToken: string;
  location: string; // window.location of the callback page
};
export type VerifyOIDCAuthResult = {
  authToken: string;
};
export function verifyOIDCAuth(data: VerifyOIDCAuthVariables) {
  return post<VerifyOIDCAuthResult>('/v1/auth/oidc/verify', data).catch((err: unknown) => {
    rewordValidationMessages(err, (details) => {
      if (details.path === 'oidcRequestToken' && details.code === 'ERR_INVALID_OIDC_REQUEST_TOKEN') {
        return 'Could not complete authentication. Please try logging in again.';
      }
      if (details.path === undefined && details.code === 'ERR_OIDC_PROVIDER_MISMATCH') {
        return 'The identity provider changed. Please try logging in again.';
      }
      if (details.path === undefined && details.code === 'ERR_OIDC_EXCHANGE_CODE') {
        return 'Communication error with the SSO provider. Please contact your Defined Networking administrator to troubleshoot the SSO configuration.';
      }
      if (details.path === undefined && details.code === 'ERR_OIDC_MISSING_CLAIMS') {
        const missingClaims = details.extra?.missingClaims;

        if (!Array.isArray(missingClaims)) {
          trackError(
            new Error(
              'expected an `extra` object with a `missingClaims` array for an error code of `ERR_OIDC_MISSING_CLAIMS`'
            )
          );
          return 'OIDC id_token returned by OIDC Provider is missing required claims';
        }

        return `OIDC id_token returned by OIDC Provider is missing required claims: ${missingClaims.join(', ')}`;
      }
    });
  });
}
