import { Notifications } from '#components/patterns/notifications/Notifications';
import { PageHeader } from '../../page-header/PageHeader';
import styles from './MainLayout.module.css';

type Props = {
  header: React.ReactElement | string;
  children?: React.ReactNode;
};

export function MainLayout({ header, children }: Props) {
  return (
    <div id="main-layout" className={styles.MainLayout}>
      <Notifications className="mb-24" />
      {typeof header === 'string' ?
        <PageHeader title={header} />
      : header}
      {children}
    </div>
  );
}
