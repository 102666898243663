import { UserIcon } from '#assets/ReactSvg';
import { Link } from '#components/atoms/link/Link';
import { Menu, MenuButton, MenuItem, useMenuStore } from '#components/patterns/menus/menu';
import { useAuth } from '#global/context/auth/useAuth';
import { useAppLocation } from '#hooks/useAppLocation';
import styles from './UserMenu.module.css';

/**
 * {@link UserMenu} displays the logged-in user's email address and a logout button in
 * a menu.
 */
export function UserMenu() {
  const { user, signout } = useAuth();
  const location = useAppLocation();
  const menu = useMenuStore();
  return (
    <>
      <MenuButton store={menu} className="menu-button pad-16">
        <UserIcon />
        <span className="ml-8">Your Account</span>
      </MenuButton>
      <Menu store={menu} gutter={8} className="menu-list ml-16">
        <div className={styles.UserMenu_email}>{user?.email}</div>
        {user?.type === 'user' && (
          <MenuItem render={<Link to={'/settings/user'} state={{ from: location }} />}>User settings</MenuItem>
        )}
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
