import cn from 'classnames';
import styles from './Spacer.module.css';

type Props = {
  children: React.ReactNode;
  /** Should the children be arranged horizontally or vertically? */
  direction?: 'horizontal' | 'vertical';
  /** Controls the spacing between children */
  size?: 'narrow' | 'default' | 'wide' | 'xwide' | 'xxwide';
  /** At --size-breakpoint-s and below, force vertical orientation */
  stackWhenNarrow?: boolean;
  /** At --size-breakpoint-xs and below, force vertical orientation */
  stackWhenXNarrow?: boolean;
  /** Additional class names to apply */
  className?: string;
};

/**
 * {@link Spacer} is useful for adding a consistent spacing between elements,
 * either horizontally (by default) or vertically.  It will apply the spacing to
 * direct children of the Spacer itself.
 */
export function Spacer({
  children,
  className,
  size = 'default',
  direction = 'horizontal',
  stackWhenNarrow,
  stackWhenXNarrow,
}: Props) {
  return (
    <div
      className={cn(styles[`Spacer___${direction}` as const], styles[`Spacer___${size}` as const], className, {
        [styles.Spacer___stackWhenNarrow]: stackWhenNarrow,
        [styles.Spacer___stackWhenXNarrow]: stackWhenXNarrow,
      })}
    >
      {children}
    </div>
  );
}
