import cn from 'classnames';
import { Children } from 'react';
import styles from './Nav.module.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export function Nav({ children, className }: Props) {
  const listChildren = Children.map(children, (child) => {
    return <li className="flex flex-col">{child}</li>;
  });

  return (
    <nav className={cn(styles.Nav, className)} id="page-navigation">
      <ul className={styles.Nav_list}>{listChildren}</ul>
    </nav>
  );
}
