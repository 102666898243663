import cn from 'classnames';
import styles from './UserIcon.module.css';

type Props = React.SVGAttributes<SVGElement>;

export function UserIcon({ className, ...props }: Props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className={cn(styles.UserIcon, className)} {...props}>
      <circle className={styles.UserIcon_circle} cx="16" cy="16" r="16" />
      <path
        className={styles.UserIcon_profile}
        d="M16 16.167a3.167 3.167 0 100-6.334 3.167 3.167 0 000 6.334zM16 16.833a5.005 5.005 0 00-5 5 .334.334 0 00.333.334h9.334a.333.333 0 00.333-.334 5.005 5.005 0 00-5-5z"
      />
    </svg>
  );
}
