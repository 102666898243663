import cn from 'classnames';
import { Banner } from '#components/atoms/banner/Banner';
import { Spacer } from '#components/patterns/spacer/Spacer';
import { useNotifications } from '#global/context/notifications/useNotifications';

type Props = {
  className?: string;
};

export function Notifications({ className }: Props) {
  const { notifications } = useNotifications();

  if (!notifications.length) return null;

  return (
    <Spacer className={cn(className)} direction="vertical">
      {notifications.map((n) => {
        return (
          <Banner key={n.id} variant={n.type}>
            {n.children}
          </Banner>
        );
      })}
    </Spacer>
  );
}
