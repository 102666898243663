import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route } from 'react-router-dom';
import { fallbackRenderNotFound } from '#screens/errors/fallbackRenderNotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { fromArray, Titled } from '#util/title';

/**
 * {@link rolesRootRoute} contains all of the routing config for the /roles path
 */
export const rolesRootRoute = (
  <Route
    element={
      <Titled title={(title) => fromArray(['Roles', title])}>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Titled>
    }
  >
    <Route
      index={true}
      lazy={() => import('#route-bundles/authenticated').then(({ RolesPage }) => ({ Component: RolesPage }))}
    />
    <Route
      path="add"
      lazy={() => import('#route-bundles/authenticated').then(({ AddRolePage }) => ({ Component: AddRolePage }))}
    />
    <Route
      path=":roleId"
      lazy={() =>
        import('#route-bundles/authenticated').then(({ EditRolePage }) => ({
          element: (
            <ErrorBoundary fallbackRender={fallbackRenderNotFound}>
              <EditRolePage />
            </ErrorBoundary>
          ),
        }))
      }
    />
  </Route>
);
