import { useSuspenseNetworksList } from '#api/networks.queries';
import { Protected } from './Protected';

type Props = {
  children: React.ReactNode;
};

/**
 * A component that redirects to the GettingStarted page if there is no network created.
 */
export function GotStarted({ children }: Props) {
  const networks = useSuspenseNetworksList().data.data;

  const hasNetworks = Boolean(networks.length);

  return (
    <Protected isPermitted={() => hasNetworks} redirectTo="/get-started">
      {children}
    </Protected>
  );
}
