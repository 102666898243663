import cn from 'classnames';
import { cloneElement, forwardRef } from 'react';
import styles from './IconButton.module.css';

type SvgComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  /** Width of the SVG */
  width?: number | string;
  /** Height of the SVG */
  height?: number | string;
  /** Optional additional class names to apply to the button element */
  className?: string;
  /** Accessible button name for screen readers */
  title: string;
  /** Reactified svg component */
  children: React.ReactElement<SvgComponent, string | React.JSXElementConstructor<SvgComponent>>;
};

/**
 * {@link IconButton} can be used to create a button out of an svg icon, along with an accessible title
 * which is not displayed on the screen.
 */
export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ width = 16, height = 16, className, title, children, ...buttonProps }, ref) => {
    const svgChild = cloneElement(children, { width, height, 'aria-hidden': true });

    return (
      <button className={cn('btn icon-btn pad-8', className)} type="button" ref={ref} {...buttonProps}>
        {svgChild}
        <span className={cn('visually-hidden', styles.IconButton_visuallyHiddenText)}>{title}</span>
      </button>
    );
  }
);
IconButton.displayName = 'IconButton';
