import cn from 'classnames';
import styles from './Hamburger.module.css';

type Props = {
  onClick: () => void;
  isOpen: boolean;
  /** The id of the element that this button shows and hides */
  ariaControls?: string;
};

export function Hamburger({ onClick, isOpen, ariaControls = 'page-navigation' }: Props) {
  return (
    <button
      className={cn('btn', styles.Hamburger)}
      type="button"
      aria-pressed={isOpen}
      aria-label="Navigation Menu"
      aria-controls={ariaControls}
      onClick={onClick}
    >
      <svg width={24} fill="none" viewBox="0 0 24 18">
        <title>{isOpen ? 'Close menu' : 'Open menu'}</title>
        <rect
          className={cn(styles.Hamburger_top, { [styles.Hamburger_top___open]: isOpen })}
          width="24"
          height="2"
          rx="1"
          fill="#6E7D91"
        />
        <rect
          className={cn(styles.Hamburger_middle, { [styles.Hamburger_middle___open]: isOpen })}
          y="8"
          width="24"
          height="2"
          rx="1"
          fill="#6E7D91"
        />
        <rect
          className={cn(styles.Hamburger_bottom, { [styles.Hamburger_bottom___open]: isOpen })}
          y="16"
          width="24"
          height="2"
          rx="1"
          fill="#6E7D91"
        />
      </svg>
    </button>
  );
}
