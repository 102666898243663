import { forwardRef } from 'react';
import { default as ArrowRightIcon } from '#assets/arrow-right.svg?react';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export const ArrowRightButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size, title = 'Arrow right', ...buttonProps }, ref) => {
    return (
      <IconButton title={title} height={size} width={size} ref={ref} {...buttonProps}>
        <ArrowRightIcon />
      </IconButton>
    );
  }
);
ArrowRightButton.displayName = 'ArrowRightButton';
