import cn from 'classnames';
import { ExternalIcon } from '#assets/ReactSvg';
import { NavLink } from '#components/atoms/nav/nav-link/NavLink';
import styles from './NavGroupItem.module.css';

type Props = Exclude<React.ComponentProps<typeof NavLink>, 'className'> & {
  className?: string;
  activeClassName?: string;
  external?: boolean;
};

export function NavGroupItem({
  children,
  to,
  external,
  className,
  activeClassName,
  reloadDocument,
  ...restProps
}: Props) {
  const isDefinedLink = typeof to === 'string' && /^(?:https:\/\/)?.*\.defined\.net/.test(to);

  return (
    <NavLink
      className={cn(styles.NavGroupItem, className)}
      activeClassName={cn(styles.NavGroupItem___active, activeClassName)}
      to={to}
      // We want to reload if either are true; if external is false, we still want to check reloadDocument.
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      reloadDocument={external || reloadDocument}
      target={external ? '_blank' : undefined}
      rel={isDefinedLink ? undefined : 'noreferrer'}
      {...restProps}
    >
      {({ isActive, isPending, isTransitioning }) => {
        const renderedChildren =
          typeof children === 'function' ? children({ isActive, isPending, isTransitioning }) : children;
        return (
          <>
            {renderedChildren}
            {external ?
              <ExternalIcon className={styles.NavGroupItem_externalIcon} width="10" height="10" />
            : null}
          </>
        );
      }}
    </NavLink>
  );
}
