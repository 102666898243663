import cn from 'classnames';
import styles from './CodeBlock.module.css';

type Props = {
  children: React.ReactNode;
  /** If true, allow lines to scroll horizontally, otherwise they'll wrap */
  allowScroll?: boolean;
  /** For long strings like urls and tokens, allow to break line in middle of word if necessary */
  breakWord?: boolean;
  /** Adds a `$` to the left of each line, especially helpful with long commands which might wrap */
  addPrompt?: boolean;
  /** Success variant has a green background */
  variant?: 'info' | 'success';
  className?: string;
};

/**
 * The CodeBlock component is intended to be used to show simple examples of code,
 * usually CLI commands or similar.
 */
export function CodeBlock({ children, className, allowScroll, addPrompt, breakWord, variant = 'info' }: Props) {
  return (
    <pre
      className={cn(styles.CodeBlock, className, {
        [styles.CodeBlock___allowScroll]: allowScroll,
        [styles.CodeBlock___breakWord]: breakWord,
        [styles.CodeBlock___addPrompt]: addPrompt,
        [styles.CodeBlock___isSuccess]: variant === 'success',
      })}
      // Fixes <https://dequeuniversity.com/rules/axe/4.4/scrollable-region-focusable>
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={allowScroll ? 0 : undefined}
    >
      {typeof children === 'string' ?
        <code>{children}</code>
      : children}
    </pre>
  );
}
