import { useEffect, useState } from 'react';
import { useMatchMedia } from './useMatchMedia';

type ColorScheme = 'light' | 'dark';

/**
 * {@link usePrefersColorScheme} is a react hook that checks and watches for changes to
 * the user's OS-level theme preferences, and returns either 'light' or 'dark'
 * based on that preference, falling back to a default if no preference is set.
 */
export function usePrefersColorScheme(defaultScheme: ColorScheme) {
  const [scheme, setScheme] = useState<ColorScheme>(() =>
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  const isLight = useMatchMedia('(prefers-color-scheme: light)');
  const isDark = useMatchMedia('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (isDark) {
      setScheme('dark');
    } else if (isLight) {
      setScheme('light');
    } else {
      setScheme(defaultScheme);
    }
  }, [isDark, isLight, defaultScheme]);

  return scheme;
}
