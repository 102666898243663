import { useContext } from 'react';
import { AuthContext } from './AuthContext';

/**
 * {@link useAuth} can be used by components in our app to get access to the user state
 * and authentication methods
 */
export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
