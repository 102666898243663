import { Protected } from '#components/patterns/routing/Protected.jsx';
import { useAuth } from '#global/context/auth/useAuth.jsx';

type Props = Omit<React.ComponentPropsWithoutRef<typeof Protected>, 'isPermitted'>;

export function IsMFAUser({ children, ...props }: Props) {
  const { user } = useAuth();

  return (
    <Protected isPermitted={() => user?.type === 'user'} {...props}>
      {children}
    </Protected>
  );
}
