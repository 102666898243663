import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';

// derived from
// - https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
// - https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// - https://mantine.dev/hooks/use-timeout/

/**
 * {@link useTimeout} provides `window.setTimeout` functionality in a hook format.
 * This will cancel the timeout on component unmount.
 * @param handler Function to call when the timeout completes. Changing this value will not reset the timeout
 * @param delay Number of milliseconds to wait before calling handler. Will reset timeout on change.
 * @returns Calling `start()` or `clear()` will reset the timeout.
 */
export function useTimeout(
  handler: () => void,
  delay: number | undefined
): { start: () => void; clear: () => void; isStarted: boolean } {
  const [timeoutState, setTimeoutState] = useState<'started' | 'cleared'>('cleared');
  const timeoutRef = useRef<number | undefined>(undefined);
  const savedHandler = useRef<() => void>(handler);
  useEffect(() => {
    // use a ref to keep a current callback ref
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    // reset timeout on delay or timeoutState change.
    const tick = () => {
      flushSync(() => {
        savedHandler.current();
        setTimeoutState('cleared');
      });
    };
    if (typeof delay === 'number' && timeoutState === 'started') {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay, timeoutState]);
  return {
    start: () => setTimeoutState('started'),
    clear: () => setTimeoutState('cleared'),
    isStarted: timeoutState === 'started',
  };
}
