import { useContext } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

/**
 * {@link useGlobalState} can be used by components in our app to get and set specific pieces of global state.
 */
export function useGlobalState() {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
}
