import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route } from 'react-router-dom';
import { fallbackRenderNotFound } from '#screens/errors/fallbackRenderNotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';

export const routesRootRoute = (
  <Route
    element={
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    }
  >
    <Route
      index={true}
      lazy={() => import('#route-bundles/authenticated').then(({ RoutesPage }) => ({ Component: RoutesPage }))}
    />
    <Route
      path="add"
      lazy={() => import('#route-bundles/authenticated').then(({ AddRoutePage }) => ({ Component: AddRoutePage }))}
    />
    <Route
      path=":routeId"
      lazy={() =>
        import('#route-bundles/authenticated').then(({ EditRoutePage }) => ({
          element: (
            <ErrorBoundary fallbackRender={fallbackRenderNotFound}>
              <EditRoutePage />
            </ErrorBoundary>
          ),
        }))
      }
    />
  </Route>
);
