import { Protected } from '#components/patterns/routing/Protected';
import { useAuth } from '#global/context/auth/useAuth';

export function HasUser({ children }: { children: React.ReactNode }) {
  const { userToken } = useAuth();

  return (
    <Protected isPermitted={() => Boolean(userToken)} redirectTo="/auth/login">
      {children}
    </Protected>
  );
}
