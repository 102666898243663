import { useEffect } from 'react';
import { usePrefersColorScheme } from './hooks/usePrefersColorScheme';

export function Theme() {
  const scheme = usePrefersColorScheme('dark');

  useEffect(() => {
    document.documentElement.dataset.userColorScheme = scheme;
  }, [scheme]);

  return null;
}
