import { AuthLayout } from '#components/patterns/layouts/auth/AuthLayout';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';

/**
 * Shows a loading spinner in an auth layout (pages with a top bar, and no nav sidebar)
 */
export function LoadingAuthPage() {
  return (
    <AuthLayout centerVertically={true}>
      <LoadingSpinner />
    </AuthLayout>
  );
}
