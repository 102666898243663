import { assert } from 'assert-ts';

// This is the token which expires periodically, requires 2fa
export const AUTH_TOKEN_NAME = 'authtoken';
// This is the user session token, requires magic link
export const USER_TOKEN_NAME = 'usertoken';
// This is a token which was returned from the api after an OIDC auth
export const OIDC_TOKEN_NAME = 'oidtoken';

/**
 * Return a cookie value from a given key
 */
export const getItem = (key: string) => {
  return document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(assert(storedValue)) : total;
  }, '');
};

/**
 * Set a cookie value on the root path.
 * @param numberOfDays sets the expiration date to now + numberOfDays in the future
 */
export const setItem = (key: string, value: string, numberOfDays?: number) => {
  let expires = '';
  if (numberOfDays !== undefined) {
    const expiresDate = new Date();
    // set the time to be now + numberOfDays
    expiresDate.setTime(expiresDate.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
    expires = `expires=${expiresDate.toUTCString()};`;
  }

  document.cookie = `${key}=${encodeURIComponent(value)};${expires} samesite=strict; path=/; secure`;
};
