import { AuthLayout } from '#components/patterns/layouts/auth/AuthLayout';
import { Oops } from './Oops';

type Props = React.ComponentProps<typeof Oops>;

export function OopsPage(props: Props) {
  return (
    <AuthLayout>
      <Oops {...props} className="pad-0" />
    </AuthLayout>
  );
}
