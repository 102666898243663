import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { makeMaxResourceQueryFn } from '#util/api';
import { networkQueryKeys, Networks } from '.';

export const networksQueryOptions = queryOptions({
  queryKey: networkQueryKeys.list(),
  queryFn: ({ signal }) => Networks.getNetworks({ init: { signal } }),
});

export const makeNetworkQueryOptions = (networkId: string) =>
  queryOptions({
    queryKey: networkQueryKeys.detail(networkId),
    queryFn: ({ signal }) => Networks.getNetwork(networkId, { signal }),
  });

export const maxNetworksQueryOptions = queryOptions({
  queryKey: networkQueryKeys.maxList,
  queryFn: makeMaxResourceQueryFn(Networks.getNetworks),
});

export function useNetworksList() {
  return useQuery(networksQueryOptions);
}

export function useSuspenseNetworksList() {
  return useSuspenseQuery(networksQueryOptions);
}

export function useSuspenseNetworkQuery(networkId: string) {
  return useSuspenseQuery(makeNetworkQueryOptions(networkId));
}

/** {@link networkCountQueryOptions} returns the total number of networks in the account */
export const networkCountQueryOptions = queryOptions({
  queryKey: ['networkCount'],
  queryFn: async ({ signal }) => (await Networks.getNetworks({ pageSize: 1, init: { signal } })).metadata.totalCount,
});

export function useSuspenseNetworkCount() {
  return useSuspenseQuery(networkCountQueryOptions);
}
