import { forwardRef } from 'react';
import { CopyIcon } from '#assets/ReactSvg';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export const CopyIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size, title = 'Copy', ...buttonProps }, ref) => {
    return (
      <IconButton title={title} height={size} width={size} ref={ref} {...buttonProps}>
        <CopyIcon />
      </IconButton>
    );
  }
);
CopyIconButton.displayName = 'CopyIconButton';
