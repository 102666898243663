import cn from 'classnames';
import { Notifications } from '#components/patterns/notifications/Notifications';
import styles from './CenteredLayout.module.css';

type Props = {
  children: React.ReactNode;
  Illustration?: React.ComponentType<{ className?: string; height: number }>;
  className?: string;
  /** Set to true when used on a page that should not show global notifications */
  preventNotifications?: boolean;
};

/**
 * CenteredLayout can be used for empty or error states, with an optional illustration.
 */
export function CenteredLayout({ children, Illustration, preventNotifications, className }: Props) {
  return (
    <div className={cn('flex flex-col', styles.CenteredLayout, className)}>
      {preventNotifications ? null : <Notifications />}
      <div className={cn('flex flex-col align-center justify-center', styles.CenteredLayout_content)}>
        {Illustration && <Illustration className={cn('mb-24', styles.CenteredLayout_illustration)} height={170} />}
        {children}
      </div>
    </div>
  );
}
