import * as Ariakit from '@ariakit/react';
import cn from 'classnames';
import { forwardRef, type ComponentPropsWithoutRef } from 'react';
import styles from './Tooltip.module.css';

export type Placement = Ariakit.TooltipStoreState['placement'];

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'content'> {
  content: React.ReactNode;
  onHidden?: () => void;
  open?: boolean;
  placement?: Placement;
  timeout?: number;
  render?: Ariakit.TooltipAnchorProps['render'];
  hideOnHoverOutside?: boolean;
  showOnHover?: boolean;
  flip?: Ariakit.TooltipProps['flip'];
  portal?: Ariakit.TooltipProps['portal'];
}

export const Tooltip = forwardRef<HTMLDivElement, Props>(function Tooltip(
  {
    className,
    content,
    onHidden,
    open,
    placement,
    hideOnHoverOutside,
    showOnHover,
    flip,
    portal,
    timeout,
    ...buttonProps
  },
  ref
) {
  const tooltip = Ariakit.useTooltipStore({
    timeout,
    open,
    placement,
    setMounted(mounted) {
      if (!mounted) {
        onHidden?.();
      }
    },
  });

  return (
    <Ariakit.TooltipProvider store={tooltip}>
      <Ariakit.TooltipAnchor
        ref={ref}
        className={cn('tooltip', className)}
        {...buttonProps}
        showOnHover={showOnHover}
      />
      <Ariakit.Tooltip
        className={cn(styles.Tooltip)}
        gutter={4}
        hideOnHoverOutside={hideOnHoverOutside}
        flip={flip}
        portal={portal}
      >
        <Ariakit.TooltipArrow />
        {content}
      </Ariakit.Tooltip>
    </Ariakit.TooltipProvider>
  );
});
