/**
 * {@link createQueryKeyFactories} gives us a standard method of creating basic query key factories
 * for a particular resource type.  These can be used with react-query and standardizes our
 * query keys, making it easier to interact with the query cache from different locations.
 *
 * See {@link https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories} for the
 * inspiration to this approach.
 *
 * @param baseKey The resource name, e.g. 'hosts', or 'networks'
 * @returns An object containing queryKey factories like all() and lists()
 */
function createQueryKeyFactories(baseKey: string) {
  const all = () => [baseKey];
  const lists = () => [...all(), 'list'] as const;
  const list = (
    cursor?: string,
    pageSize?: number,
    ...rest: Array<string | number | Record<string, string | number | boolean> | undefined>
  ) => [...lists(), { cursor, pageSize, ...rest }] as const;
  const details = () => [...all(), 'detail'] as const;
  const detail = (id: string) => [...details(), id] as const;

  return { all, lists, list, details, detail };
}

export const hostQueryKeys = {
  ...createQueryKeyFactories('hosts'),
  maxList: createQueryKeyFactories('hosts').list(undefined, Number.POSITIVE_INFINITY),
};

export const lighthouseQueryKeys = createQueryKeyFactories('lighthouses');

export const relayQueryKeys = createQueryKeyFactories('relays');

export const networkQueryKeys = {
  ...createQueryKeyFactories('networks'),
  maxList: createQueryKeyFactories('networks').list(undefined, Number.POSITIVE_INFINITY),
};

export const roleQueryKeys = {
  ...createQueryKeyFactories('roles'),
  roleName: (roleId: string) => [...createQueryKeyFactories('roles').details(), 'roleName', roleId],
  maxList: createQueryKeyFactories('roles').list(undefined, 500),
};

export const tagQueryKeys = {
  ...createQueryKeyFactories('tags'),
  maxList: createQueryKeyFactories('tags').list(undefined, Number.POSITIVE_INFINITY),
};

export const userQueryKeys = {
  all: () => ['users'],
  current: () => [...userQueryKeys.all(), 'current'],
};

export const oidcProviderQueryKeys = createQueryKeyFactories('oidcProviders');

export const orgQueryKeys = {
  all: () => ['org'],
  featureFlags: () => [...orgQueryKeys.all(), 'featureFlags'],
};

export const totpAuthenticatorQueryKeys = createQueryKeyFactories('totpAuthenticators');

export const auditLogQueryKeys = {
  ...createQueryKeyFactories('auditLogs'),
  csv: ({ targetType, timeFrame, targetId }: { targetType?: string; timeFrame?: string; targetId?: string }) =>
    ['auditLogs', 'csv', { targetType, timeFrame, targetId }] as const,
};

export const apiKeyQueryKeys = {
  ...createQueryKeyFactories('apiKeys'),
  maxList: createQueryKeyFactories('apiKeys').list(undefined, 500),
};

export const enrollmentCodeQueryKeys = {
  forHost: (hostId: string) => ['enrollment-code', hostId],
  check: (code: string) => ['enrollment-code', 'check', code],
};

export const certificateAuthorityQueryKeys = createQueryKeyFactories('certificateAuthorities');

export const billingQueryKeys = {
  billingDetails: ['billing', 'billingDetails'],
};

export const routeQueryKeys = {
  ...createQueryKeyFactories('routes'),
  maxList: createQueryKeyFactories('routes').list(undefined, 500),
};
