import { AUTH_TOKEN_NAME, OIDC_TOKEN_NAME, USER_TOKEN_NAME } from '#util/cookies';
import { useCookie } from './useCookie';

/**
 * {@link useAuthTokens} provides the user and auth tokens and their setters.
 * It can be given default values, useful mostly just for tests.
 */
export function useAuthTokens(
  defaultUserTokenValue?: string,
  defaultAuthTokenValue?: string,
  defaultOIDCTokenValue?: string
) {
  // The userToken corresponds to the sessionToken from the server.  It requires a magic link to obtain.
  const [userToken, setUserToken, deleteUserToken] = useCookie(USER_TOKEN_NAME, defaultUserTokenValue);
  // The authToken is valid for a short period of time (16 hours) and requires 2FA to obtain.
  const [authToken, setAuthToken, deleteAuthToken] = useCookie(AUTH_TOKEN_NAME, defaultAuthTokenValue);
  // The oidcToken is an auth token that was obtained from an OIDC authentication
  const [oidcToken, setOIDCToken, deleteOIDCToken] = useCookie(OIDC_TOKEN_NAME, defaultOIDCTokenValue);
  return {
    userToken,
    setUserToken,
    deleteUserToken,
    authToken,
    setAuthToken,
    deleteAuthToken,
    oidcToken,
    setOIDCToken,
    deleteOIDCToken,
  };
}
