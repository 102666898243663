import { AuthContext } from './AuthContext';
import { useAuthProvider } from './useAuthProvider';

type Props = {
  children: React.ReactNode;
};

/**
 * {@link AuthProvider} is a context provider component to make useAuth() available to our app.
 */
export function AuthProvider({ children }: Props) {
  // We set some initial values in development, when we are mocking api responses.
  const auth = useAuthProvider({
    oidcToken: __IS_MOCK_SSO__ ? 'mockOIDCToken' : undefined,
    userToken: __IS_MOCKED__ && !__IS_MOCK_SSO__ ? 'mockUserToken' : undefined,
    authToken: __IS_MOCKED__ && !__IS_MOCK_SSO__ ? 'mockAuthToken' : undefined,
  });
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
