import { useState } from 'react';
import * as Cookies from '#util/cookies';

/**
 * Custom hook to retrieve and store cookies for our application.
 */
export function useCookie(key: string, defaultValue = '') {
  const getCookie = () => Cookies.getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(() => getCookie());

  const updateCookie = (value: string, numberOfDays?: number) => {
    Cookies.setItem(key, value, numberOfDays);
    setCookie(value);
  };

  const deleteCookie = () => {
    Cookies.setItem(key, '', 0);
    setCookie('');
  };
  return [cookie, updateCookie, deleteCookie] as const;
}
