import type { TOTPAuthenticatorListItem } from '#dn-types/totp-authenticators';
import { rewordValidationMessages } from './errors';
import { del, get, post, put } from './fetch';

// GET /totp-authenticators
type GetAuthenticatorsResult = TOTPAuthenticatorListItem[];
export function getAuthenticators(init?: RequestInit) {
  return get<GetAuthenticatorsResult>('/v1/totp-authenticators', init);
}

// GET /totp-authenticators/:id
type GetAuthenticatorResult = TOTPAuthenticatorListItem;
export function getAuthenticator(id: string, init?: RequestInit) {
  return get<GetAuthenticatorResult>(`/v1/totp-authenticators/${id}`, init);
}

// PUT /totp-authenticators/:id
type EditAuthenticatorResult = TOTPAuthenticatorListItem;
export type EditAuthenticatorVariables = {
  id: string;
  values: {
    name: string;
  };
};
export function editAuthenticator({ id, values }: EditAuthenticatorVariables) {
  return put<EditAuthenticatorResult>(`/v1/totp-authenticators/${id}`, values);
}

// DELETE /totp-authenticators/:id
export type DeleteAuthenticatorsResult = Record<string, never>;
export function deleteAuthenticator(id: string) {
  return del<DeleteAuthenticatorsResult>(`/v1/totp-authenticators/${id}`);
}

// POST /totp-authenticators
export type RequestMFAAuthenticatorResult = {
  totpToken: string;
  url: string;
  secret: string;
};
export function requestMFAAuthenticator() {
  return post<RequestMFAAuthenticatorResult>('/v1/totp-authenticators').catch((err: unknown) => {
    rewordValidationMessages(err, (details) => {
      if (details.path === undefined && details.code === 'ERR_LIMIT_EXCEEDED') {
        // If we got a limit from the api, we'll use that
        if (typeof details.extra?.limit === 'number') {
          return `You have already created the maximum number of authenticators for this organization (${details.extra.limit}).`;
        }
        // Otherwise, we need to be more generic
        return 'You have already created the maximum number of authenticators for this organization.';
      }
    });
  });
}

// POST /verify-totp-authenticator
export type RegisterMFAVariables = {
  totpToken: string;
  code: string;
  name?: string;
};
export type RegisterMFAResult = {
  authToken: string;
};
export function registerMFA(formData: RegisterMFAVariables) {
  return post<RegisterMFAResult>('/v1/verify-totp-authenticator', formData).catch((err: unknown) => {
    rewordValidationMessages(err, (details) => {
      if (details.path === 'totpToken' && details.code === 'ERR_INVALID_TOTP_TOKEN') {
        return 'The QR code expired, please re-scan and try again.';
      }
      if (details.path === 'code' && details.code === 'ERR_INVALID_TOTP_CODE') {
        return 'The code was not valid — perhaps it expired?';
      }
    });
  });
}
