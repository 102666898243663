import { useState } from 'react';
import { useAppUpdateAvailable } from './useAppUpdateAvailable';

export type InitialState = {
  appVersion?: string;
};

/**
 * {@link useGlobalStateProvider} hook creates and manages global state.
 */
export function useGlobalStateProvider(initialState: InitialState = {}) {
  const { appVersion = undefined } = initialState;

  const [globalError, setGlobalError] = useState<Error | undefined>();
  const updatedAppAvailable = useAppUpdateAvailable(appVersion);
  const fullPageloadNeeded = Boolean(updatedAppAvailable || globalError);

  // Return the state and setters
  return {
    fullPageloadNeeded,
    setGlobalError,
  };
}
