import { GlobalStateContext } from './GlobalStateContext';
import { useGlobalStateProvider, type InitialState } from './useGlobalStateProvider';

type Props = {
  children: React.ReactNode;
  /** So far, initial state is only used for testing, but could be useful for 'pre-seeding' initial global state on app startup */
  initialState?: InitialState;
};

/**
 * {@link GlobalStateProvider} is a context provider component to make useGlobalState() available to our app.
 */
export function GlobalStateProvider({ children, initialState }: Props) {
  const state = useGlobalStateProvider(initialState);
  return <GlobalStateContext.Provider value={state}>{children}</GlobalStateContext.Provider>;
}
