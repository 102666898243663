import cn from 'classnames';
import styles from './Banner.module.css';

type Props = {
  children: React.ReactNode;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement> & { title?: string }>;
  variant?: 'default' | 'info' | 'alert' | 'success';
  className?: string;
  iconClassName?: string;
};

export function Banner({ children, className, iconClassName, Icon, variant = 'default' }: Props) {
  return (
    <div className={cn(styles.Banner, className, styles[`Banner___${variant}`])}>
      {Icon ?
        <div className="flex align-center">
          <Icon height={16} width={16} className={cn(iconClassName, 'mr-8 shrink-0')} aria-hidden={true} />
          <div>{children}</div>
        </div>
      : children}
    </div>
  );
}
