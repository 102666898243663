import cn from 'classnames';
import styles from './SkipLink.module.css';

type Props = {
  /** Set `href` to an id of an element on page like `#page-main` to skip to it for keyboard users */
  href: string;
  children: React.ReactNode;
};

/** A component for skipping past large sections of content when navigating via keyboard */
export function SkipLink({ href, children }: Props) {
  return (
    <a className={cn('a', styles.SkipLink)} href={href}>
      {children}
    </a>
  );
}
