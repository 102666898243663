import cn from 'classnames';
import { NavLink } from '#components/atoms/nav/nav-link/NavLink';
import styles from './NavItem.module.css';

type Props = React.ComponentProps<typeof NavLink>;

export function NavItem({ children, className, ...restProps }: Props) {
  return (
    <NavLink className={cn(styles.NavItem, className)} activeClassName={styles.NavItem___active} {...restProps}>
      {children}
    </NavLink>
  );
}
