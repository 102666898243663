const separator = ' - ';

const root = 'Defined Networking';

export function fromArray(titles: string[]) {
  return titles.join(separator);
}

export function page(subtitle: string) {
  return [subtitle, root].join(separator);
}

export { Titled } from 'react-titled';
