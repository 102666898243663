import { forwardRef } from 'react';
import { default as ArrowLeftIcon } from '#assets/arrow-left.svg?react';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export const ArrowLeftButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size, title = 'Arrow left', ...buttonProps }, ref) => {
    return (
      <IconButton title={title} height={size} width={size} ref={ref} {...buttonProps}>
        <ArrowLeftIcon />
      </IconButton>
    );
  }
);
ArrowLeftButton.displayName = 'ArrowLeftButton';
