import type { ConditionalKeys } from 'type-fest';
import { trackError } from '#api/errors';
import type { FeatureFlagsResult } from '#api/org';
import { useSuspenseFeatureFlagsQuery } from '#api/org.queries';
import { NotFound } from '#screens/404/NotFound';
import { Protected } from './Protected';

type Props = {
  /** {@link featureFlagIdent} only allows feature flags with a boolean or undefined value */
  featureFlagIdent: ConditionalKeys<FeatureFlagsResult, boolean | undefined>;
  children: React.ReactNode;
  redirectTo?: string;
};

/**
 * {@link FeatureFlagged} is a component that does not allow access without the feature flag enabled.
 */
export function FeatureFlagged({ featureFlagIdent, children, redirectTo }: Props) {
  // Get feature flags
  const { data: featureFlags } = useSuspenseFeatureFlagsQuery();

  const ff = featureFlagIdent ? featureFlags[featureFlagIdent] : undefined;

  // We don't want to render the child if the feature flag is not a boolean
  if (typeof ff !== 'boolean') {
    trackError(`FeatureFlagged only works with boolean flags, ${featureFlagIdent} typeof value is ${typeof ff}`);
    return <NotFound />;
  }

  return (
    <Protected isPermitted={() => ff} redirectTo={redirectTo}>
      {children}
    </Protected>
  );
}
